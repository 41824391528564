<widget-header
    [name]="widgetName"
    [editable]="isEditable">

  <op-widget-project-details-menu
    slot="menu"
    [resource]="resource">
  </op-widget-project-details-menu>
</widget-header>

<div class="grid--widget-content">
  <p>
    <strong>Project details have now moved to a column on the right edge of this page.</strong>
  </p>
  <p>
    Starting with version 14.0, project attributes can be grouped in sections and enabled and disabled at a project level. <a href="#">Learn more</a>
  </p>
  <i>This widget can now be removed or replaced. It will be deleted in subsequent versions.</i>
</div>
