// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { InjectField } from 'core-app/shared/helpers/angular/inject-field.decorator';
import { PrincipalRendererService } from 'core-app/shared/components/principal/principal-renderer.service';
import { ResourcesDisplayField } from './resources-display-field.module';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';

export class MultipleUserFieldModule extends ResourcesDisplayField {
  @InjectField() principalRenderer:PrincipalRendererService;

  public render(element:HTMLElement):void {
    const users = this.value as HalResource[];
    // The flex display of the nested op-principal-list breaks the height if
    // this element's display is set to "initial".
    element.style.display = 'block';
    element.innerHTML = '';
    element.setAttribute('title', users.map((el) => el.name).join(', '));

    if (users.length === 0) {
      this.renderEmpty(element);
    } else {
      this.principalRenderer.renderAbbreviated(element, users);
    }
  }
}
