<form
  class="spot-modal form -vertical confirm-form-submit--modal password-confirm-dialog--modal loading-indicator--location"
  data-indicator-name="modal"
  (submit)="confirmAndClose($event)"
>
  <div id="spotModalTitle" class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container form">
    <div class="form--field -required">
      <label class="form--label -bold"
             for="request_for_confirmation_password"
             [attr.aria-label]="additionalText.field_description"
             [textContent]="additionalText.password">
      </label>
      <div class="form--field-container">
        <div class="form--text-field-container">
          <input type="password"
                 #passwordConfirmationField
                 [(ngModel)]="password_confirmation"
                 id="request_for_confirmation_password"
                 name="request_for_confirmation_password"
                 class="form--text-field">
        </div>
      </div>
      <div class="form--field-instructions" [textContent]="additionalText.field_description"></div>
    </div>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-modal--cancel-button spot-action-bar--action"
        [textContent]="additionalText.cancel_button"
        (click)="closeMe()"
        >
      </button>
      <button
        type="submit"
        class="button button_no-margin -primary spot-action-bar--action"
        [textContent]="additionalText.confirm_button"
        data-test-selector="confirmation-modal--confirmed"
        [disabled]="!passwordValuePresent()">
      </button>
    </div>
  </div>
</form>
