<ng-container *ngIf="(workPackage$ | async) as workPackage">
  <display-field *ngIf="detailed"
                 [resource]="workPackage"
                 [displayFieldOptions]="{ writable: false }"
                 containerType="single-view"
                 fieldName="status">
  </display-field>
  <display-field [resource]="workPackage"
                 [displayFieldOptions]="{ writable: false }"
                 fieldName="type">
  </display-field>
  <a class="work-package--quickinfo preview-trigger"
     target="_top"
     [href]="workPackageLink"
     [attr.data-work-package-id]="workPackage.id">
    #{{workPackage.id}}:
  </a>
  <display-field [resource]="workPackage"
                 [displayFieldOptions]="{ writable: false }"
                 fieldName="subject">
  </display-field>
  <ng-container *ngIf="detailed && (!!workPackage.startDate || !!workPackage.dueDate || !!workPackage.date)">
    (<display-field *ngIf="!!workPackage.date"
                   [resource]="workPackage"
                   [displayFieldOptions]="{ writable: false }"
                   fieldName="date"
                   containerType="single-view">
    </display-field>
    <display-field *ngIf="!!workPackage.startDate"
                   [resource]="workPackage"
                   [displayFieldOptions]="{ writable: false }"
                   [displayClass]="combinedDateDisplayField"
                   fieldName="startDate"
                   containerType="single-view">
    </display-field>
    <display-field *ngIf="!workPackage.startDate && !!workPackage.dueDate"
                   [resource]="workPackage"
                   [displayFieldOptions]="{ writable: false }"
                   [displayClass]="combinedDateDisplayField"
                   fieldName="dueDate"
                   containerType="single-view">
    </display-field>)
  </ng-container>
</ng-container>
<span *ngIf="error">
  <i class="icon icon-error"></i>
  <em [textContent]="error"></em>
</span>
