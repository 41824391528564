<div
  class='op-issue--title'
  [textContent]="gitlabIssue.title"
></div>

<span class='op-issue--state' [ngClass]="'op-issue--state_' + state">

  <svg *ngIf="state === 'open'" op-gitlab-issue-open-icon size="small"></svg>
  <svg *ngIf="state !== 'open'" op-gitlab-issue-closed-icon size="small"></svg>

  {{state}} |
  <a
  [ngClass]="'op-issue--link_' + state"
  [href]="gitlabIssue.htmlUrl"
  target="blank"
  [textContent]="gitlabIssue.repository + '#' + gitlabIssue.number"
  ></a>
</span>

<button *ngIf="gitlabIssue.labels && gitlabIssue.labels.length > 0" (click)="toggleLabels(gitlabIssue.number)" class='op-issue--label_icon' title="Show/Hide labels">
   <svg class="op-issue--label_icon_style" op-gitlab-labels-icon size="small"></svg>
</button>

<div class="op-issue--info_created">
  {{ text.label_created_by }}
  <!-- <img
    alt='Issue author avatar'
    class='op-issue--avatar op-avatar op-avatar_mini'
    [src]="gitlabIssue.gitlabUser.avatarUrl"
    *ngIf="gitlabIssue.gitlabUser"
  />&nbsp; -->
  <span class='op-issue--user'>
    <a
      [href]="'mailto:' + gitlabIssue.gitlabUser.email"
      class='op-issue--username'
      [textContent]="gitlabIssue.gitlabUser.login"
      *ngIf="gitlabIssue.gitlabUser"
    ></a>.
  </span>
  <span class='op-issue--date'>
    {{ text.label_last_updated_on }}
    <op-date-time [dateTimeValue]="gitlabIssue.gitlabUpdatedAt"></op-date-time>
  </span>.
</div>

<div
  class="op-issue--labels"
  [ngClass]="'op-issue--labels-' + gitlabIssue.number" style="display: none;">
  <span *ngFor="let label of gitlabIssue.labels">
    <span class='op-issue--label' [ngStyle]="{'background-color': label.color}" >
      {{label.title}}
    </span>
  </span>
</div>
