<div class="spot-modal spot-modal_wide op-wp-table-export"
    tabindex="0"
    cdkFocusInitial
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true">

  <div id="spotModalTitle" class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <ul *ngIf="exportOptions && !ganttOption" class="op-export-options op-export-options-grid" [ngClass]="{'-hidden': isLoading}">
      <li
        *ngFor="let option of exportOptions"
        class="op-export-options--option"
      >
        <a
          [attr.href]="option.url"
          class="op-export-options--option-link"
          (click)="triggerByOption(option, $event)"
          id="export-{{ option.identifier }}"
        >
          <op-icon icon-classes="icon-export-{{ option.identifier }} icon-big"></op-icon>
          <span class="op-export-options--option-label" [textContent]="option.label"></span>
        </a>
      </li>
    </ul>
    <div *ngIf="exportOptions && ganttOption" class="gantt-export-options">
      <form class="form">
        <section class="form--section">
          <h3 class="form--section-title">{{text.ganttOptionSectionTitle}}</h3>
          <div *ngFor="let field of ganttFieldsArray" class="form--field">
            <label
              for="{{field.id}}"
              class="form--label">
              {{ field.label }}
            </label>
            <div class="form--field-container">
              <div class="form--select-container">
                <select class="form--select"
                        [attr.id]="field.id"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="field.value">
                  <option *ngFor="let option of field.options"
                          [textContent]="option.label"
                          [ngValue]="option.value">
                  </option>
                </select>
              </div>
            </div>
        </div>
        </section>
        <div>
          <button class="button -primary" (click)="exportGantt($event)">{{text.ganttExport}}</button>
        </div>
      </form>
    </div>
  </div>
  <div class="spot-action-bar hidden-for-mobile">

    <div *ngIf="ganttOption" class="spot-action-bar--left">
      <button
        type="button"
        class="button"
        (click)="closeGanttOptions($event)"
      >
        <op-icon icon-classes="button--icon icon-arrow-left1"></op-icon>
        {{ text.backButton }}
      </button>
    </div>

    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-modal--cancel-button spot-action-bar--action"
        (click)="closeMe()"
        >
        {{ text.cancelButton }}
     </button>
    </div>
  </div>
</div>
