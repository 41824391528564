<div class="wp-create-button">
  <button
    class="button -primary add-work-package"
    [disabled]="disabled"
    [attr.aria-label]="text.explanation"
    opTypesCreateDropdown
    [projectIdentifier]="projectIdentifier"
    [stateName]="stateName$ | async"
    [dropdownActive]="allowed"
    [title]="text.title"
  >
    <span class="spot-icon spot-icon_add"></span>
    <span class="button--text"
          [textContent]="text.createWithDropdown"
          aria-hidden="true"></span>
    <span class="spot-icon spot-icon_dropdown"></span>
  </button>
</div>
